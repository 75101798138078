html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.app {
  text-align: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: aliceblue;
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.75)), url("../static/IMG_0932.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 50px;
}

h1{
  font-size: xx-large;
}

h2 {
  padding-top: 50px;
  font-style: italic;
}